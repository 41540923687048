import React, { useEffect } from 'react'
import Subnavigation from './subnavigation'
import Section from './section'
import Footer from './footer'
import Seo from './seo'
import './page.scss'
import { sendDetailViewEvent } from '../utils/analytics'
import { getProductFromSearchParams, getProductFromSlug } from '../utils/path'
import StickyBar from './sticky-bar'
import classnames from 'classnames'

const Page = ({ sections, subnavigation, footer, seoMetadata, location, stickyBarControl, stickyBarText }) => {
  useEffect(() => {
    const shouldSendAnalytics = seoMetadata && seoMetadata.products

    if (shouldSendAnalytics) {
      const metadataProducts = seoMetadata.products
      const productFromSearchParams = getProductFromSearchParams(metadataProducts, location)
      const productFromSlug = getProductFromSlug(metadataProducts, location)
      const firstProduct = metadataProducts[0]
      const productForAnalytics = productFromSearchParams || productFromSlug || firstProduct
      sendDetailViewEvent(productForAnalytics)
    }
  }, [seoMetadata, location])

  const pageNeedsToAccommodateStickyBar = !footer && stickyBarControl

  return (
    <>
      {/*
        TODO: For some reason gatsby or contentful or something is returning an empty
        subnavigation object here even when there is no subnavigation reference for the page in Contentful.
      */}
      {subnavigation && subnavigation.items && subnavigation.items.length > 0 && (
        <Subnavigation {...subnavigation} location={location} />
      )}

      <div
        className={classnames(
          'cms-page',
          { 'cms-page--has-sticky-bar': pageNeedsToAccommodateStickyBar }
        )}
      >
        {/* TODO: Check if the lang has to be added to the Seo Metadata content Model itself. */}
        {/* TODO: Default title from contentful? */}
        <Seo title='Telia Dot' lang='fi' {...seoMetadata} />

        {sections.map((section, index) => {
          return <Section key={section.id} {...section} location={location} index={index} />
        })}

        {stickyBarControl && (
          <StickyBar linkText={stickyBarText} />
        )}

        {footer && (
          <Footer {...footer} hasStickyBar={stickyBarControl} />
        )}
      </div>
    </>
  )
}

export default Page
