import React from 'react'
import { graphql } from 'gatsby'
import Page from '../components/page'

const ContentfulPage = ({ data, location }) => (
  <Page {...data.contentfulPage} location={location} />
)

export const pageQuery = graphql`
  query PageQuery($id: String) {
    contentfulPage(id: { eq: $id }) {
      id
      subnavigation {
        ...Subnavigation
      }
      seoMetadata {
        ...SeoMetadata
      }
      sections {
        ...Section
      }
      footer {
        ...Footer
      }
      stickyBarControl
      stickyBarText
    }
  }
`

export default ContentfulPage
